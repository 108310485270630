<template>
  <register-container>
    <template slot="header">
      {{ pageTitle || defaultTitle }}
    </template>
    <template slot="subtitle">{{ pageSubtitle }} </template>
    <provider-profile
      :picture="this.appointment.employeePicture"
      :name="this.appointment.employeeName"
      :onDemand="onDemand"
      :showPicture="showPicture"
    >
      <template slot="content">
        <div class="appointment-details d-flex flex-column pa-0 pa-sm-5">
          <!-- First half of appointment info -->
          <div class="mb-3" v-if="!onDemand">
            <div class="mb-1" v-if="this.appointment.appointmentDate">
              <span class="font-weight-bold">
                {{ $t('registration.registerConfirm.appointmentDate') }}:</span
              >
              {{ this.appointment.appointmentDate }}
            </div>
            <div class="my-1" v-if="this.appointment.appointmentTime">
              <span class="font-weight-bold"
                >{{ $t('registration.registerConfirm.appointmentTime') }}:</span
              >
              {{ this.appointment.appointmentTime }}
            </div>

            <div class="my-1">
              <span class="font-weight-bold"
                >{{ $t('registration.registerConfirm.appointmentDuration') }}:</span
              >
              {{ this.appointment.appointmentDuration }}
            </div>
          </div>
          <!-- Second half of appointment info -->
          <div class="mt-3">
            <div class="my-1 location" v-if="location">
              <span class="font-weight-bold">{{ $t('main.location') }}:</span> {{ location }}
            </div>
            <div class="my-1">
              <span class="font-weight-bold"
                >{{ $t('registration.registerConfirm.appointmentType') }}:</span
              >
              {{ this.appointment.appointmentType }}
            </div>
            <div class="my-1">
              <span class="font-weight-bold"
                >{{ $t('registration.registerConfirm.reasons') }}:</span
              >
              {{ this.appointment.appointmentReasons }}
            </div>
          </div>
        </div>
      </template>
    </provider-profile>
  </register-container>
</template>

<style lang="scss">
.appointment-details {
  height: 100%;
  width: 100%;
}
.back-button {
  border: 1px solid var(--v-secondary-lighten1);
}
.back-button:hover {
  background-color: var(--v-secondary-base) !important;
  color: white !important;
}
.confirm-buttons {
  position: absolute;
  bottom: 15vh;
  width: 60%;
}
.confirm-buttons > button {
  width: 200px !important;
  border-radius: 8px !important;
}

.confirm-buttons > button:hover {
  font-weight: bold;
}
.location {
  white-space: pre;
}
.my-container {
  max-width: 950px !important;
}
.nowrap {
  white-space: nowrap;
}
.schedule-visit {
  height: 70px !important;
  border-radius: 8px;
}
@media all and (max-width: 600px) {
  .back-button {
    margin-bottom: 2px;
  }
  .confirm-buttons {
    position: static;
    width: 100%;
    padding: 8px;
  }
  .appointment-details {
    div {
      div {
        span {
          display: block;
        }
      }
    }
  }
}
@media all and (max-width: 768px) {
  .appointment-details {
    text-align: center;
  }
}
</style>
<script>
import moment from 'moment';
import to from 'await-to-js';
import { PRETTY_DATE_FORMAT, TWELVE_HOUR_TIME_FORMAT } from '../constants/moment';
import { EMPLOYEE } from '../constants/roles';
import RegisterContainer from '../components/RegisterContainer.vue';
import ProviderProfile from '../components/ProviderProfile.vue';
import GET_USERS_BY_PROVIDER from '../graphql/Query/GetUsersByProvider.gql';

export default {
  name: 'RegisterConfirm',
  components: { ProviderProfile, RegisterContainer },
  data() {
    return {
      showPicture: true,
    };
  },
  computed: {
    store() {
      return this.$store.state;
    },
    appointment() {
      const {
        appointmentDate,
        appointmentDuration,
        appointmentReasons,
        appointmentTime,
        appointmentType,
        employeeName,
        employeePicture,
      } = this.$store.state.registration;

      const types = {
        inPerson: this.$t('main.inPerson'),
        video: this.$t('main.video'),
        primaryPhone: this.$t('main.phone'),
      };
      return {
        appointmentDate: appointmentDate
          ? moment(appointmentDate).format(PRETTY_DATE_FORMAT)
          : 'N/A',
        appointmentTime: appointmentTime
          ? `${moment(appointmentTime).format(TWELVE_HOUR_TIME_FORMAT)} ${this.localUserTimezone}`
          : 'N/A',
        employeeName,
        employeePicture,
        appointmentType: types[appointmentType] || 'None Selected',
        appointmentDuration: `${appointmentDuration} minutes`,
        appointmentReasons,
        appointmentRequest: !appointmentTime,
      };
    },
    defaultTitle() {
      return this.appointment.appointmentRequest
        ? this.$t('registration.registerConfirm.confirmApr')
        : this.$t('registration.registerConfirm.title');
    },
    localUserTimezone() {
      return moment.tz(this.$store.getters.timezone).format('z');
    },
    location() {
      if (!this.appointment.location?.address) return null;
      const { address } = this.appointment.location;
      if (!address) return null;
      const formattedAddress = `${this.appointment.location.name}\n${address.streetNumber}${
        address.suite ? `-${address.suite}` : ''
      } ${address.streetName}\n${address.city}, ${address.province}, ${address.country}\n${
        address.postalCode
      }
        `;
      return formattedAddress;
    },
    onDemand() {
      return this.$store.state.registration.onDemand;
    },
  },
  methods: {
    async getEmployeeInfo() {
      if (!this.$store.state.registration.employeeId) return;
      const { employeeId: userId } = this.$store.state.registration;
      const [errors, query] = await to(
        this.$apollo.query({
          query: GET_USERS_BY_PROVIDER,
          variables: {
            providerId: this.$store.state.registration.providerId,
            type: EMPLOYEE,
            userId,
          },
        })
      );

      if (!errors) {
        if (!query?.data?.getUsersByProvider[0]) return;
        this.appointment.employeeName = this.$t(
          'format.fullName',
          query?.data?.getUsersByProvider[0]
        );
        this.appointment.employeePicture = query?.data?.getUsersByProvider[0].profilePictureUrl;
      }
    },
  },
  mounted() {
    this.$store.commit('setCompleted', true);
    this.$store.commit('setOnNextClick', () => {
      this.$store.commit('setCompleted', false);
      if (this.isPageflowV2) {
        this.$emit('next');
      } else {
        this.$router.push({ name: this.$store.getters.nextFlowRoute(this.$route.name) });
      }
    });
    this.getEmployeeInfo();
  },
};
</script>
